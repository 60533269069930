/**
 * Main entrypoint of the application.
 * Here is initialized the ControllerDispatcher and loaded the main resources shared by all pages
 */

import 'intersection-observer';
import './modules/utils/EelementRemovePolyfill';
import './modules/utils/NodeListForEachPolyfill';
import './modules/utils/RemoveAppendPolyfill';
import 'core-js/fn/set';
import 'core-js/fn/promise';
import 'core-js/fn/symbol';
import 'core-js/fn/object';
import 'core-js/fn/array';

import 'what-input'; // needed for accessibility
import '../fonts/**';
import '../stylesheets/main.scss';
import '../stylesheets/devmode.scss';

import './modules/configurations/public-path';


// Load the controller dinamically based on the data attribute of the body
import ControllerDispatcher from './modules/utils/ControllerDispatcher';
// import Observer from './modules/utils/Observer';

let ctrDispatcher = new ControllerDispatcher();
ctrDispatcher.dispatchToController();

// const inViewportCb = (entries, observer) => {
//   entries.forEach(entry => {
//     // Are we in viewport?
//     if (entry.intersectionRatio > 0) {
//       observer.unobserve(entry.target);
//       entry.target.classList.add('in-viewport');
//     }
//   });
// };

// const inViewport = new Observer('.observe', Math.floor(window.outerHeight / 3) * -1, null, inViewportCb);
